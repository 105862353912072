import { ChainId, Token } from '@pancakeswap/sdk'

export const bscTokens = {
  wbnb: new Token(
    ChainId.BSC,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETHW',
    'PowerSwap Wrapped ETHW',
    'https://mainnet.ethwscan.com/'),
  cake: new Token(
    ChainId.BSC,
    '0xDC10EcEE74ce57f1DA8D194639F232A1F71c9c71',  
    18,
    'POWER',
    'PowerSwap Token',
  ),
  busd: new Token(
    ChainId.BSC,
    '0xd955b4fC5F7Bc5D36d826780C1207AB1C4705c9A',   
    18,
    'PUSD',
    'PowerSwap USD',
  ),
  cusdt: new Token(
    ChainId.BSC,
    '0x2AD7868CA212135C6119FD7AD1Ce51CFc5702892',   
    6,
    'USDT',
    'Chainge USDT',
  ),
  cusdc: new Token(
    ChainId.BSC,
    '0x25DE68ef588cb0c2c8F3537861E828Ae699CD0DB',   
    6,
    'USDC',
    'Chainge USDC',
  ),
  xen: new Token(
    ChainId.BSC,
    '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',   
    18,
    'XEN',
    'XENCrypto',
  ),
  wusdc: new Token(
    ChainId.BSC,
    '0x11bbB41B3E8baf7f75773DB7428d5AcEe25FEC75',   
    6,
    'USDC',
    'UNIWSwap USD Coin',
  ),
  twit: new Token(
    ChainId.BSC,
    '0xB7c40AB8f773dB75158939E543cB559e987dBFFC',   
    6,
    'TWIT',
    'Twit Coin',
  ),
}
