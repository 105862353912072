import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('Partner'),
    items: [
      {
        label: 'Interfi',
        href: 'https://www.interfi.network/',
      },
      {
        label: 'Wagmi33',
        href: 'https://pow.wagmi33.org/ETHW',
      },
      {
        label: 'Geckoterminal',
        href: 'https://www.geckoterminal.com/ethw/powerswap/pools',
      },
      {
        label: 'Nswap',
        href: 'https://www.nswap.com/',
      },
      {
        label: 'OpenW',
        href: 'https://www.openwnft.xyz/',
      },
    ],
  },
  {
    label: 'Resource',
    items: [
      {
        label: 'Audit Report Swap',
        href: 'https://www.interfi.network/audits#PowerSwap',
      },
      {
        label: 'Audit Report Farm',
        href: 'https://www.interfi.network/audits#PowerSwapMasterchef',
      },
      {
        label: 'Docs',
        href: 'https://docs.powerswap.fi/',
      },
    ],
  },

  {
    label: 'Contact us',
    items: [
      {
        label: 'Career Opportunity',
        href: 'mailto:kbvczi@gmail.com',
      },
      {
        label: 'Business Cooperation',
        href: 'https://t.me/marketingcryptobb',
      },
    ],
  },

  {
    label: 'Bridge',
    items: [
      {
        label: 'Chainge',
        href: 'https://openapi.chainge.finance/app?fromChain=BSC&toChain=ETHW&fromToken=USDT&toToken=USDT',
      },
    ],
  },

  // {
  //   label: t('About'),
  //   items: [
  //     {
  //       label: t('Contact'),
  //       href: 'mail://kbvczi@gmail.com',
  //       isHighlighted: true,
  //     },
  //     {
  //       label: t('Blog'),
  //       href: 'https://medium.com/@PowerSwapOfficial',
  //     },
  //     {
  //       label: t('Community'),
  //       href: 'https://discord.com/invite/QcXZy3SaxJ',
  //     },
  //     {
  //       label: t('Litepaper'),
  //       href: 'https://docs.powerswap.fi/',
  //     }
  //   ],
  // },
  /* {
    label: t('Help'),
    items: [
      {
        label: t('Customer Support'),
        href: 'https://docs.pancakeswap.finance/contact-us/customer-support',
      },
      {
        label: t('Troubleshooting'),
        href: 'https://docs.pancakeswap.finance/help/troubleshooting',
      },
      {
        label: t('Guides'),
        href: 'https://docs.pancakeswap.finance/get-started',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/pancakeswap',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.pancakeswap.finance',
      },
      {
        label: t('Bug Bounty'),
        href: 'https://docs.pancakeswap.finance/code/bug-bounty',
      },
      {
        label: t('Audits'),
        href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
      },
      {
        label: t('Careers'),
        href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
      },
    ],
  }, */
]
